import Link from 'next/link';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { DisplayType } from '@shared/types/enums';
import HeaderBar from '@ui/components/HeaderBar/HeaderBar';
import MediaQuery from '@ui/components/Responsive/MediaQuery';
import Settings from '@ui/components/Settings/Settings';
import Box from '@ui-v2/core/Box/Box';
import Image from '@ui-v2/core/Image/Image';
import { getDropdownValue } from '@utils/dropdownUtils';
import useCmsTranslation from '@utils/hooks/useCmsTranslation';
import { getVendorLink } from '@utils/vendorUtils';
import { useConstants } from '@web/context/ConstantContext';
import { useCountries } from '@web/context/hooks/useCountries';
import usePartnerHeader from '@web/context/hooks/usePartnerHeader';
import usePartnerInfo from '@web/context/hooks/usePartnerInfo';
import usePartnerRouter from '@web/context/hooks/usePartnerRouter';
import { useSettings } from '@web/context/SettingsContext';
import ChatWidgetLauncher from './ChatWidgetLauncher';

const ImageWrapper = styled.span<{ logoWidth?: Maybe<number> }>(
  ({ logoWidth }) => css`
    display: flex;
    width: ${logoWidth || 210}px;
    cursor: pointer;

    > img {
      height: auto;
      max-height: ${!logoWidth ? '50px' : 'initial'};
      object-position: left;
    }
  `,
);

const ExternalLinkWrapper = styled.span(
  ({ theme }) => css`
    margin-right: ${theme.spacings[20]}px;
    color: white;
    font-weight: bold;
    text-decoration: underline;
  `,
);

const HiddenTitle = styled.h1`
  position: absolute;
  clip: rect(0, 0, 0, 0);
`;

const HeaderContainer = () => {
  const { locale } = useConstants();
  const { asPath, getPartnerHref } = usePartnerRouter();
  const { partnerWebsiteUrl, platformName } = usePartnerInfo();
  const headerData = usePartnerHeader();
  const countries = useCountries();
  const { t } = useCmsTranslation();

  const {
    currencies,
    currency,
    hideCurrencySelection,
    hideResidencySelection,
    languages,
    mainCurrencies,
    residency,
  } = useSettings();

  const partnerWebsite = getVendorLink({
    links: partnerWebsiteUrl,
    locale,
    residency,
  });

  const externalLink = getVendorLink({
    links: headerData?.faqLinks,
    locale,
    residency,
  });

  return (
    <HeaderBar image={headerData?.headerBarImage?.asset}>
      {headerData?.logo.url && (
        <Link
          aria-label={partnerWebsite?.label || platformName?.value}
          href={
            headerData.logoLinkToPartnerPage && partnerWebsite?.link
              ? partnerWebsite.link
              : '/'
          }
          // Because of our i18n default settings, we get a console
          // warning that this href is rendered as /en on the server but / on the client
          // This is totally fine and can be ignored
          suppressHydrationWarning
          target="_self"
        >
          <ImageWrapper logoWidth={headerData.logoWidth}>
            <Image
              alt={headerData.logo.alt}
              height={headerData.logo.height}
              objectFit="contain"
              priority
              src={headerData.logo.url}
              width={headerData.logo.width}
            />
          </ImageWrapper>
        </Link>
      )}
      <HiddenTitle>
        {`${platformName?.value} ${t('search_results', 'Search results')}`}
      </HiddenTitle>
      <Box display="flex" gap={16}>
        {externalLink?.link && (
          <MediaQuery fromDisplay={DisplayType.Small}>
            <Link href={externalLink.link} target="_blank">
              <ExternalLinkWrapper>{externalLink.label}</ExternalLinkWrapper>
            </Link>
          </MediaQuery>
        )}

        <ChatWidgetLauncher />
        <Settings
          countries={getDropdownValue(countries)}
          currencies={getDropdownValue(currencies)}
          currency={currency}
          getHref={getPartnerHref}
          hideCurrencySelection={hideCurrencySelection}
          hideResidencySelection={hideResidencySelection}
          languages={getDropdownValue(languages)}
          locale={locale}
          mainCurrencies={getDropdownValue(mainCurrencies)}
          residency={residency}
          route={asPath}
        />
      </Box>
    </HeaderBar>
  );
};

export default HeaderContainer;
